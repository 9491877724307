<template>
    <div class="grow flex flex-col translate-y-0 transition-transform duration-x ease-in-out translate-x-0">
        <section class="flex flex-col text-left mt-20-screen ml-20" style="margin-left: 5%">
            <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex val_gradient">Terms of Service</h1>
            <p class="text-xl text-white mb-4">Last Updated: 05.03.2022</p>
            <p class="text-xl text-white mb-4">Please read these terms and conditions before using our service</p>
            <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex val_gradient">Definitionen</h1>
            <ul style="list-style: disc; padding-left: 2rem">
                <li class="text-white"><b>Service</b> - Refers to VALORANT LABS (can be referred to as "we", "us", "our").</li>
                <li class="text-white">
                    <b>Discord</b> - Refers to <a href="https://discord.com/" class="val_gradient">Discord</a> as a messaging platform (our service is not affiliated
                    with, maintained sponsored or endoresed by Discord)
                </li>
                <li class="text-white"><b>Discord Account</b> - Refers to an individual's account that is created on Discord.</li>
                <li class="text-white">
                    <b>Riot Games</b> - Refers to <a href="https://www.riotgames.com/de" class="val_gradient">Riot Games</a> as a company (our service is not affiliated
                    with, maintained sponsored or endoresed by Riot Games)
                </li>
                <li class="text-white"><b>Riot Games Account</b> - Refers to an individual's account that is created at Riot Games</li>
                <li class="text-white"><b>ID</b> - Unique Identifier</li>
                <li class="text-white">
                    <b>Valorant</b> - Refers to <a href="https://playvalorant.com/en-us/" class="val_gradient">VALORANT</a> as a game, developed by
                    <a href="https://www.riotgames.com/de" class="val_gradient">Riot Games</a> as of the time that this Terms and Conditions page is written
                </li>
                <li class="text-white"><b>Valorant Match</b> - Refers to a game/match of Valorant that has been played</li>
                <li class="text-white"><b>Account</b> - Relates to an unique account from Discord or Riot Games</li>
                <li class="text-white">
                    <b>Website</b> - refers to the website of VALORANT LABS, accessible at
                    <a href="https://valorantlabs.xyz/" class="val_gradient">https://valorantlabs.xyz</a>
                </li>
                <li class="text-white">
                    <b>You </b> - Refers to the individual that is using our service when an account is registered by storing the Riot Games ID and Discord ID together
                </li>
            </ul>
            <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex val_gradient">Acknowledgement</h1>
            <p class="text-xl text-white mb-4">
                These are the general terms and conditions that govern the use of our service and the agreement that exists between you and our Service. These terms and
                conditions govern the rights and obligations of all users with respect to the use of our Service.
            </p>
            <p class="text-xl text-white mb-4">
                Your access to and use of our Service is conditioned upon your acceptance of and compliance with these terms and conditions. These Terms and Conditions
                apply to all visitors, users and others who access or use our Service.
            </p>
            <p class="text-xl text-white mb-4">
                By accessing or using our Service, you agree to be bound by these Terms and Conditions. If you do not agree to any part of these Terms and Conditions, you
                may not access our Service.
            </p>
            <p class="text-xl text-white mb-4">You confirm that you are not under 16 years old. Our service does not allow persons under the age of 16 to use it</p>
            <p class="text-xl text-white mb-4">
                Access to our Service is conditional upon your acceptance of and compliance with our Privacy Policy. Our Privacy Policy describes our collection policies
                and procedures, use and disclosure of your personal information when you use our Service, and informs you of your privacy rights and about, how the law
                protects you. Please read our Privacy Policy carefully before using our Service.
            </p>
            <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex val_gradient">Termination of the rights of use</h1>
            <p class="text-xl text-white mb-4">
                We may immediately terminate or suspend your access for any reason, without prior notice or liability, to suspend, including, but not limited to, if we
                believe that you have violated these Terms and Terms and Conditions.
            </p>
            <p class="text-xl text-white mb-4">Upon termination, your right to use the Service will immediately terminate.</p>
            <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex val_gradient">Liability and warranty</h1>
            <p class="text-xl text-white mb-4">
                Our service is provided to you in an up-to-date form. A large part of our service is provided by third party providers such as APIs, image files from Riot
                Games or Discord. We are not responsible for any problems, changes, downtimes, quality changes caused by third parties on which our our product is based
                on.
            </p>
            <p class="text-xl text-white mb-4">
                In the event of these changes to our third-party resources, we will try to prioritize maintenance and maintain the level of quality. This means that we
                cannot guarantee that our service will remain available at the same quality level forever, if one of our third party providers is unable to provide their
                information to us.
            </p>
            <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex val_gradient">Rules of conduct</h1>
            <p class="text-xl text-white mb-4">We require that you comply with these obligations when you use our product:</p>
            <ul style="list-style: disc; padding-left: 2rem">
                <li class="text-white">You must be at least 16 years old.</li>
                <li class="text-white">
                    Accounts may not be shared with other users unless they both own the Discord account and and the Riot Games account linked to the account from our
                    Service.
                </li>
                <li class="text-white">We may not deactivate your account if there are no violations.</li>
                <li class="text-white">
                    No targeted actions or attempts by you to degrade or disable the performance of our Service, which includes, but is not limited to, replaying/spam of
                    our functions of our Service and attacks such as, for example A DDOS attack on our website or Discord.
                </li>
                <li class="text-white">Any attempts to abuse or exploit the API of Riot Games through our service are not permitted.</li>
                <li class="text-white">If we believe that you are using our Service for purposes that we believe are malicious.</li>
            </ul>
            <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex val_gradient">Links to other websites</h1>
            <p class="text-xl text-white mb-4">
                Our Service may contain links to other websites or Discord servers that are not operated by us or with which we are affiliated
            </p>
            <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex val_gradient">Changes to the General Terms and Conditions</h1>
            <p class="text-xl text-white mb-4">
                The Terms and Conditions may be changed from time to time. We will endeavor to notify you 30 days prior to any changes to the Terms and Conditions to the
                extent that there are changes.
            </p>
            <p class="text-xl text-white mb-4">We will announce changes on our Discord. We will also change the date mentioned above.</p>
            <h1 class="h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold text-white flex val_gradient">Contact us.</h1>
            <p class="text-xl text-white mb-4">
                If you have any questions or concerns about these Terms and Conditions, you can contact us directly at via contact@valorantlabs.xyz or via the Discord
                server.
            </p>
        </section>
    </div>
    <custom-footer></custom-footer>
</template>

<style scoped>
.val_gradient {
    background-image: linear-gradient(310deg, #d60808, #ff6690);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hdev_gradient {
    background-image: linear-gradient(310deg, #eb6a0a, #edcf27);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.green_gradient-bg {
    background-image: linear-gradient(310deg, #233329, #63d471);
}

.val_gradient-bg {
    background-image: linear-gradient(310deg, #d60808, #ff6690);
}
</style>

<script>
import CustomFooter from '../../components/footer.vue';
import axios from 'axios';

export default {
    name: 'Tos',
    components: {CustomFooter},
    props: ['lang'],
    watch: {
        async lang(newLang, oldLang) {
            if (oldLang != newLang) {
                this.llang = newLang;
                this.i18n = (await axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/public/i18n/${newLang}`).catch(e => e)).data ?? {};
            }
        },
    },
    data() {
        return {
            llang: this.getCookie('lang') ?? 'en',
            i18n: {},
        };
    },
    async created() {
        this.i18n = (await axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/public/i18n/${this.llang}`).catch(e => e)).data ?? {};
    },
    computed: {},
    methods: {
        localString(num) {
            return num.toLocaleString();
        },
        getCookie(name) {
            const nameEQ = name + '=';
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
        getTranslation(name) {
            let object = this.i18n;
            const split = name.split('.');
            for (let i = 0; split.length > i; i++) {
                if (typeof object != 'object') {
                    object = null;
                    break;
                }
                object = object[split[i]];
            }
            return object ?? name;
        },
        setCookie(name, value) {
            document.cookie = `${name}=${value}; path=/`;
        },
    },
};
</script>
